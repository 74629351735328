.sectionview-table-container {
  border-radius: 24px;
  border: red solid 1px;
  padding: 12px;
}

.sectionview-spinner-page {
  display: flex;
  height: 72vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}
