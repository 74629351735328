.compare-section-root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 160px;
  padding-top: 64px;
  align-items: stretch;
  width: 80vw;
  height: 80vh;
}
.compare-section-options-part {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.section-info-box {
  height: 80px;
  width: 400px;
  border-radius: 12px;
  box-shadow: 0 0 8px #ccc;
  margin: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 6px 24px;
}

.compare-section-chart-part {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.compare-section-checkbox {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}
