.heatmap-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.heatmap-chart-column-card {
  display: flex;
  flex-direction: column;
  width: 45%;
  box-shadow: 0px 5px 12px #ccc;
  border-radius: 24px;
  /* border: red solid 1px; */
  padding: 12px;
}
