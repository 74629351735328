.benchmark-settings {
  padding: 40px;
}

.benchmark-settings-heading {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;

  color: #29282d;
}

.benchmark-settings-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 30px;
}

.benchmark-settings-menu-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.benchmark-settings-menu-main-heading {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #29282d;
}

.benchmark-settings-menu-sub-heading {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 100;
  font-size: 14px;
  color: #7a7980;
}

.benchmark-settings-on-off-setting-toggle {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 100;
  font-size: 12px;

  color: #7a7980;
}

.benchmark-settings-submit {
  align-self: center;
}

.benchmark-settings-submit-button {
}
