.highlight-card-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.highlight-video-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
