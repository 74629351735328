/******************************
* 色管理
* 色名参考 ref: https://www.colorhexa.com/
*******************************/

// TODO 色は要整理
$palette-brown: #6d5e57;
$palette-brown-light1: #f4ece4;
$palette-brown-light2: #f9f6f4;
$palette-brown-light3: #e2c9b5;

// summary text
$palette-gray: #999999;

// sidenav
$sidenav-background: #efe3d8;
$sidenav-background-hover: #e2c9b5;
$sidenav-text: #6d5e57;
$sidenav-btn: #6d5e57;

// form
$form-input-background: #f2f2f2;
// $form-primary: #ea9da6;
$form-primary: #fa9f11;


// error
$error-red: #db3e3e;
$error-red-light1: #e5bdbd;

// graph
$graph-border: #999999;
$graph-text: #866755;

// calendar
$calendar-pink-base: #ea98a4;
$calendar-pink-light1: #f4bdc4;
$calendar-gray:#f9f6f4;

// participants
$participants-brown: #866755;
$participants-brown-light1: #f4ece4;

// neutral
$neutral-yello: #ffcd89;

// happy
$happy-orange: #f48f57;
$happy-orange-light1: #f4a56e;
$happy-orange-light2: #f9cbb4;
$happy-orange-light3: #f2c497;

// surprise
$surprise-pink: #e66b9d;
$surprise-pink-light1: #f4c6d5;

// disgust
$disgust-magenta: #934e9f;
$disgust-magenta-light1: #dbc3ea;

// angry
$angry-dark-blue: #45379b;
$angry-dark-blue-light1: #8f8fd8;

// sad
$sad-blue: #1d5fbf;
$sad-blue-light1: #b9cfea;

// fear
$fear-cyan: #2097b7;
$fear-cyan-light1: #c4e3e8;
