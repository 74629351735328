.dashboard-permeeting-info-summary-container {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 6px #ddd;
  border-radius: 16px;
  align-items: center;
  margin-bottom: 24px;
  padding: 24px;
  gap: 12px;
  justify-content: space-between;
}

.dashboard-permeeting-highlights-container {
  height: 100%;
  width: 100%;
  /* margin-left: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 12px;
}
.permeeting-video-container {
  display: flex;
  height: 300px;
}
.dashboard-permeeting-highlights-score-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashboard-permeeting-highlight-score-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 6px #ddd;
  border-radius: 12px;
  width: 160px;
  padding: 8px 16px;
}

.dashboard-permeeting-highlight-score-title {
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 400;
}

.dashboard-permeeting-highlight-score {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 12px;
}

.dashboard-permeeting-highlight-score-text {
  font-family: Ubuntu;
  font-size: 32px;
  font-weight: 450;
  color: black;
}

.dashboard-permeeting-highlight-score-benchmark-positive-diff {
  display: flex;
  flex-direction: row;
  background-color: #80BFA2;
  border-radius: 4px;
  align-items: center;
  padding: 2px 4px;
  gap: 4px;
  justify-content: space-between;
}

.dashboard-permeeting-highlight-score-benchmark-positive-diff-text {
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  color: #FFF;
}

.dashboard-permeeting-highlight-score-benchmark-negative-diff {
    display: flex;
    flex-direction: row;
    background-color: #EC8A87;
    border-radius: 4px;
    align-items: center;
    padding: 2px 4px;
    gap: 4px;
    justify-content: space-between;
}

.dashboard-permeeting-highlight-score-benchmark-negative-diff-text {
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  color: #FFF;
}

.permetting-transcript-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.dashboard-permeeting-emotions-card {
  height: 180px;
  width: 120px;
  box-shadow: 0px 0px 6px #ddd;
  border-radius: 12px;
  padding: 12px 8px;
}

.dashboard-permeeting-emotions-entry {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  row-gap: 12px;
}

.dashboard-emotions-score-summary-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 24px; */
}

.audio-features-chart-div {
  display: flex;
  flex-direction: row;
  border-radius: 24px;
  justify-content: space-around;
}

.dashboard-per-meeting-share {
  height: 100%;
}

.dashboard-per-meeting-share img {
  width: 20px;
  cursor: pointer;
}
