.section-crud-root {
  height: 88vh;
  width: 100vw;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-crud-container {
  height: 400px;
  width: 520px;
  border-radius: 24px;
  background-color: white;
  box-shadow: 0 0 6px #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 24px;
  /* align-items: center; */
}
.section-crud-delete-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.section-crud-textfield-div {
  display: flex;
  gap: 16px;
  align-items: baseline;
}
