.download-root {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 72vh;
  align-items: center;
  justify-content: center;
  gap: 100px;
}
.yellow-medium-button {
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 120px;
  background-color: #f7cf6d;
  color: #333;
}

.yellow-medium-button-disabled {
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 120px;
  background-color: #f9ebc9;
  color: #999;
}
.no-styling-button {
  text-decoration: none;
  color: #333;
}
.download-options-container {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.download-options-root {
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
