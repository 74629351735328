.section-modal-root {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* height: 100%; */
  /* width: 100%; */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.section-modal-content {
  height: 580px;
  width: 580px;
  background-color: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section-modal-topbar {
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: flex-end;
  align-items: center;
  margin-right: 12px;
  margin-top: 12px;
  margin-bottom: 24px;
  border-bottom: solid 2px #ddd;
}
.section-modal-heading {
  margin-left: auto;
  margin-right: auto;
}
.section-modal-sectionname {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 24px;
  margin-bottom: 12px;
  align-items: center;
}
.section-modal-padding {
  padding: 0px 24px;
  flex: 3;
}

.section-modal-footer {
  padding: 24px 24px;
  align-self: center;
}
