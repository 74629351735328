/* TODO: This code is not used from each components. Can delete. */
.multi-line-chart-test-div {
  display: flex;
  height: 600px;
  width: 100%;
  box-shadow: 0 6px 12px #aaa;
  border-radius: 24px;
  margin: 24px 0;
  padding: 12px;
}
