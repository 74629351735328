.relation-condition-entry {
  display: flex;
  flex-direction: row;
  width: 80%;
  background-color: white;
  margin: 12px 0;
  box-shadow: 0 0px 6px #ddd;
  border-radius: 6px;
  padding: 6px 24px;
  align-items: center;
  justify-content: space-around;
}

.make-invisible {
  visibility: hidden;
}
