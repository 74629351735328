.edit-popup {
  background-color: #efe3d8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hightlight-scroll {
  height: 70vh;
  overflow-y: scroll;
}

.quickview-flex {
  display: flex;
  height: 100%;
}

.comments-box {
  background-color: #ffffff;
  /* width: 40%; */
  height: calc(100%);
  margin-left: 1rem;
  padding-left: 1rem;
  padding: 0.5rem;
}

.comment-heading {
  font-size: 1rem;
  padding: 0.5rem 0rem;
}

.height-100 {
  height: 100%;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.tables-wrapper {
  align-items: flex-end;
}

.two-tables {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 12.5rem);
}
