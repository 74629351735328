.shared-dashboard-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85vw;
  margin-top: 60px;
  height: 100%;
}

.shared-dashboard-container-header {
  display: flex;
  justify-content: space-between;
}

.shared-dashboard-container-header-heading {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 56px;

  display: flex;
  align-items: center;

  color: #29282d;

  display: inline;
}

.shared-dashboard-container-header-sub-heading {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 56px;
  /* or 255% */

  display: flex;
  align-items: center;

  color: #29282d;

  display: inline;

  cursor: pointer;
}

.shared-dashboard-container-header-arrow {
  display: inline;
  margin-left: 20px;
  margin-right: 20px;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 56px;

  color: #29282d;
}

.shared-dashboard-container-header-button {
  background: #f7cf6d;
  box-shadow: 0px 4px 4px rgba(41, 40, 45, 0.15);
  border-radius: 8px;

  width: 170px;
  height: 40px;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: #29282d;

  cursor: pointer;
}

.shared-dashboard-container-body {
  display: flex;
  flex-direction: row;
  min-height: 77vh;
}

/* .organization-list {
  width: 300px;
  height: 60px;
  margin-top: 20px;

  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  color: #29282d;
}

.organization-list-selected-background {
  background: #f7cf6d;
  border-radius: 8px;
  opacity: 0.75;
}

.organization-list-not-selected-background {
  background: #fffdfb;
  box-shadow: 0px 4px 15px rgba(41, 40, 45, 0.12);
  border-radius: 8px;
  opacity: 0.75;
} */

.organization-list {
  width: 100%;
}

.organization-list-table {
  width: 100%;
  margin-top: 50px;
  text-align: center;
}

.organization-list-table > thead {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.15px;

  color: #7a7980;
}

.organization-list-table tbody {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.0125em;

  color: #29282d;
}

.organization-list-table tr {
  height: 100px;
  border-bottom: 1px solid #eaeaea;
}

.shared-dashboard-container-body-components {
  width: 100%;
}

.delete-icon {
  cursor: pointer;
}

.delete-dialog-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.delete-dialog-box-heading {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 140%;
  letter-spacing: 0.0125em;

  color: #29282d;
  margin-top: 10px;
  padding: 20px;
}

.delete-dialog-box-body {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  margin-top: 100px;
}

.delete-dialog-box-delete-button {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.15px;

  color: #e32626;
  cursor: pointer;
}

.delete-dialog-box-cancel-button {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.15px;

  color: #7a7980;

  cursor: pointer;
}

/* .shared-dashboard-container-body-navbar {
  padding: 1.5rem;
  margin-left: 20px;
  display: flex;
}

.shared-dashboard-container-body-navbar-items {
  margin-right: 10px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #29282d;
} */

.shared-dashboard-container-body-component-loader {
  margin-left: 20px;
  margin-top: 20px;
}

.popup-content-shared-dashboard-content {
  background: #fffdfb;
  box-shadow: 0px 4px 15px rgba(41, 40, 45, 0.12);
  border-radius: 32px;
  max-width: 700px;
  height: 400px;
}

.shared-dashboard-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  height: 100%;
}

.shared-dashboard-popup-content-heading {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 56px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #49484f;
}

.shared-dashboard-popup-content-close-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}

.shared-dashboard-popup-content-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.shared-dashboard-popup-content-body-label {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #49484f;

  padding: 10px;
}

.shared-dashboard-popup-content-body-input {
  width: 400px;
  height: 50px;

  padding: 10px;

  border: 2px solid #eaeaea;
  border-radius: 8px;
}

.shared-dashboard-popup-content-body-btn {
  width: 112px;
  height: 48px;

  background: #f7cf6d !important;
  box-shadow: 0px 4px 4px rgba(41, 40, 45, 0.15);
  border-radius: 8px;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 56px;
  /* or 350% */

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  color: #29282d;

  margin-top: 20px;
}

.shared-dashboard-popup-content-success {
  height: 100%;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;

  color: #29282d;

  display: flex;
  justify-content: center;
  align-items: center;
}
