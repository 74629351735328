.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: 441px;
  max-height: 291px;
}