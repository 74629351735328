.user-info-root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-info-card {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 5px 12px #ddd;
  width: 90%;
  border-radius: 16px;
  height: 90px;
  /* justify-content: space-around; */
  
}

.user-info-img-container {
  height: 90px;
  width: 90px;
  border-radius: 16px;
  overflow: hidden;
}
.user-info-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: 12px;
  padding: 0 2rem;
}
