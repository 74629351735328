.movies-root-div {
  display: flex;
  flex-direction: column;
  width: 98vw;
  justify-content: center;
  align-items: center;
}

.movie-switch-root {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  /* padding: 12px; */
  border-radius: 100px;
  /* border: #f7cf6d solid 4px; */
  /* background-color: #eee; */
  box-shadow: 0 0 6px #eee;
  cursor: pointer;
}

.movie-switch-entry {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 180px;
  border-radius: 100px;
  background-color: #f7cf6d;
  transition: 800ms;
}
.movie-switch-entry-inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 180px;
  border-radius: 100px;
  background-color: white;
}

.movie-sections-root {
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin-top: 24px;
}
.section-list-element-root {
  /* background-color: #ddd; */
  padding-top: 24px;
  padding-bottom: 24px;
}

.section-list-element {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 0 6px #ddd;
  padding: 12px 24px;
  margin-top: 24px;
  margin-bottom: 12px;
  border-radius: 12px;
}
.section-list-element-active {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 0 6px #ddd;
  background-color: #f7cf6d;
  padding: 12px 24px;
  margin-top: 24px;
  margin-bottom: 12px;
  border-radius: 12px;
}
.section-list-element-expanded {
}
.section-list-movie {
  /* display: hidden; */
  display: none;
  height: 0px;
  transition: 400ms;
}
.section-list-movie-active {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.expanded-movie-element {
  display: flex;
  padding: 6px 24px;
  box-shadow: 0 0 6px #ddd;
  border-radius: 12px;
  margin: 6px 0;
  cursor: pointer;
  transition: 260ms;
  width: 86%;
}
.expanded-movie-element:hover {
  background-color: #f7cf6d;
}

.movie-section-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
  gap: 24px;
}
.yellow-big-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 160px;
  background-color: #f7cf6d;
  border-radius: 12px;
  color: #575757;
  cursor: pointer;
}
.black-big-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 160px;
  background-color: #333;
  border-radius: 12px;
  color: white;
  cursor: pointer;
}
.red-big-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 160px;
  background-color: #c00;
  border-radius: 12px;
  color: white;
  cursor: pointer;
}
.black-big-button:hover {
  background-color: #575757;
}

.yellow-big-button:hover {
  background-color: #f3d998;
}
.red-big-button:hover {
  background-color: #e00;
}
