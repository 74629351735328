.search {
  width: 100%;
}
.menu {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;
}

.item {
  padding: 2px 6px;
  cursor: default;
}

.item-highlighted {
  color: white;
  background: #6d5f57;
}

.item-header {
  background-color: #eeeeee;
  color: #454545;
  font-weight: bold;
}

.heading-color {
  color: #6d5f57;
}

.tag-suggestion {
  cursor: pointer;
}

.tag-suggestion:hover {
  color: white;
  background: #6d5f57;
}

.tag-input {
  background: #fffdfb;
  border: 1px solid rgba(234, 234, 234, 0.25);
  box-shadow: 0px 4px 5px rgba(41, 40, 45, 0.1);
  border-radius: 8px;
  width: "100%";
}

.resource-wrapper {
  display: flex;
  margin-left: 1rem;
  background-color: #eaeaea;
  cursor: pointer;
  border-radius: 10rem;
}

.resource-selected {
  background-color: #5f6ce1;
  color: white;
  border-radius: 10rem;
}

.resource-types {
  padding: 0.5rem 2rem;
}
