.dropdown-container {
  width: 300px;
  margin-left: auto;
}
.dashboard-permeeting-top-info-row {
  display: flex;
  flex-direction: row;
  height: 160px;
  gap: 40px;
}

.dashboard-permeeting-info-section {
  flex: 1;
  box-shadow: 0px 4px 12px #ddd;
  height: 100%;
  border-radius: 16px;
  padding: 24px;
}

.dashboard-permeeting-info-container {
  height: 100%;
  justify-content: space-around;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
  column-gap: 12px;
  }

.dashboard-permeeting-tag-section {
  flex: 2;
  box-shadow: 0px 4px 12px #ddd;
  height: 100%;
  border-radius: 16px;
  padding: 24px;
  max-width: 33%;
}

.info-section-entry {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  vertical-align: top;
}

.dashboard-permeeting-tag-section-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashboard-permeeting-tag-container {
  /* display: grid; */
  height: 100%;
  overflow-y: scroll;
}
.tag-bubble {
  padding: 4px 12px;
  background-color: #f4dea7;
  border-radius: 20px;
  display: inline;
  /* border: white solid 1px; */
  box-shadow: inset 0px 0px 0px 2px white;
}
.tag-bubble-container {
  padding-right: 16px;

  display: inline;
}
.transcript-details-root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  width: 80%;
  height: 88vh;
  padding: 16px;
}

.transcript-details-video-card {
  flex: 2;
  height: 100%;
  box-shadow: 0px 0px 6px #ddd;
  border-radius: 12px;
}
.transcript-details-info-column {
  flex: 3;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: 24px;
}
.transcript-details-details-card {
  width: 100%;
  flex: 2;
  box-shadow: 0px 0px 6px #ddd;
  border-radius: 12px;
}
.transcript-details-wordcloud-card {
  width: 100%;
  flex: 1;
  box-shadow: 0px 0px 6px #ddd;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 24px 48px;
  margin-top: 1rem;
}

.section-dashboard-root {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-top: 36px;
}

.section-dashboard-selector-root {
  display: flex;
  flex-direction: row;
  width: 240px;
  /* height: 36px; */
  justify-content: center;
  gap: 48px;
  align-self: flex-start;
  cursor: pointer;
}

.section-dashboard-selector-element {
  transition: all 800ms;
}
.section-dashboard-selector-element:hover {
  transition: 800ms;
}

.section-dashboard-selector-element-selected {
  border-bottom: #f7cf6d solid 4px;
  font-weight: bold;
  color: black;
}

.dashboard-per-movie-comment {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.comment-bubble-container {
  background-color: rgba(221, 221, 221, 0.524);
  margin-top: 3px;
  padding: 5px;
  word-wrap: break-word;
  color: black;
}

.dashboard-per-meeting-share-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;

  background: #fffdfb;
  box-shadow: 0px 4px 15px rgba(41, 40, 45, 0.12);
  border-radius: 32px;
  max-width: 700px;
}

.share-via-email-dialog-box {
  color: #29282d;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.share-via-email-dialog-box-heading {
  display: flex;
  justify-content: space-between;
}

.share-via-email-dialog-box-heading > :first-child {
  font-size: 25px;
}

.share-via-email-dialog-box-heading-close {
  cursor: pointer;
}

.share-via-email-dialog-box-body {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-via-email-dialog-box-body-input {
  width: 100%;
}

.share-via-email-dialog-box-body-subheading {
  font-size: 14px;
  margin-top: 10px;
}

.share-via-email-dialog-box-body-input {
  width: 100%;
  margin-top: 10px;
}
.share-via-email-dialog-box-body-button {
  text-align: center;
  margin-top: 10px;
}

.share-via-email-dialog-box-body-button span {
  background-color: #f7cf6d;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  width: 50px;
}

.share-via-email-dialog-box-body-button-disabled {
  text-align: center;
  margin-top: 10px;
}

.share-via-email-dialog-box-body-button-disabled span {
  background-color: #eaeaea;
  border-radius: 5px;
  padding: 5px;
  width: 50px;
}

.share-via-email-dialog-box-body-loading {
  width: 150px;
}

.transcripts-detail-table-share-button {
  width: 20px;
}

.transcripts-detail-table-share-button img {
  width: 100%;
  cursor: pointer;
}
