.notification-config-sections {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.notification-section-selector {
  width: 50%;
  text-align: center;
  margin-top: 1rem;
}

.selected {
  text-decoration: underline;
  text-decoration-color: #f7cf6d;
  text-decoration-thickness: 3px;
}

.config-body {
  padding: 2rem;
}

.creatreAlertSubHeading {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 400;

  color: #7a7980;
}

.creatreAlertHeading {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;

  color: #49484f;
}

.display-alert-wrapper {
  background-color: rgba(240, 248, 255, 0.568);
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 1rem;
}

.display-alert-heading {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 56px; */
  /* or 350% */
  margin: 0;

  color: #49484f;
}

.display-alert-desciption{
  font-family: "Ubuntu";
  font-style: normal;
  /* font-weight: 500;
  font-size: 16px;
  line-height: 56px; */
  /* or 350% */
  margin: 0;

  color: #49484f;
}