.header-item {
  display: flex;
  justify-content: center;
  position: relative; 
}

.header-title {
  margin: 0;
  line-height: 50px;
}

.header-icon {
  margin: 10px 40px 0 0;
  line-height: 20px;
  font-size: 20px;
  position: absolute;
  right: 0;          
}