.row-height {
  display: flex;
  flex-wrap: wrap;
}

.bar-chart-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
