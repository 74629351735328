.settingsv2-root {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-top: 40px;  
  height: calc(100vh - 11.5vh);
}

.settingsv2-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 40px;
}
.settingsv2-contents-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
}
.settingsv2-options-column {
  display: flex;
  flex-direction: column;
  flex: 2;
}
.settingsv2-options-button {
  height: 48px;
  width: 240px;
  background-color: white;
  box-shadow: 0px 0px 8px #ccc;
  margin-bottom: 24px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.settingsv2-options-button:hover {
  background-color: #f7cf6d;
}
.settingsv2-options-button-active {
  height: 48px;
  width: 240px;
  background-color: #f7cf6d;
  box-shadow: 0px 0px 8px #ccc;
  margin-bottom: 24px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settingsv2-content {
  flex: 5;
  box-shadow: 0px 0px 8px #ccc;
  border-radius: 12px;
  height: 70vh;
  overflow-y: scroll;
}
