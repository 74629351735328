.user-coloredbox-green {
  background-color: #80bfa2;
  border-radius: 4px;
  padding: 4px 12px;
  display: flex;
  flex-direction: column;
}

.user-coloredbox-red {
  background-color: #ec8a87;
  border-radius: 4px;
  padding: 4px 12px;
  display: flex;
  flex-direction: column;
}

.user-big-number-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  /* border: solid red 1px; */
}

.user-analytics-overview-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 180px;
  width: 260px;
  box-shadow: 0px 5px 12px #aaa;
  border-radius: 12px;
  padding: 12px 24px;
  transition: 200ms;
}
.user-score-cards {
  display: flex;
  flex-direction: row;
  padding: 36px;
  justify-content: space-between;
  width: 100%;
}
