.compare-section-modal-root {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* height: 100%; */
  /* width: 100%; */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.compare-section-modal-content {
  height: 360px;
  width: 580px;
  background-color: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.compare-section-modal-topbar {
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: flex-end;
  align-items: center;
  margin-right: 12px;
  margin-top: 12px;
  margin-bottom: 24px;
  border-bottom: solid 2px #ddd;
}
.compare-section-modal-heading {
  margin-left: auto;
  margin-right: auto;
}

.compare-section-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  align-items: center;
}
