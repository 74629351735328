.analytics-overview-section {
  height: 174px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.analytics-overview-root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.analytics-overview-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 120px;
  width: 240px;
  box-shadow: 0px 5px 12px #aaa;
  border-radius: 12px;
  padding: 12px 24px;
  transition: 200ms;
}

.analytics-overview-card:hover {
  transform: translateY(-4px);
}

.analytics-overview-card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.white-number-value {
  font-family: Ubuntu;
  color: white;
  font-size: 24px;
}
.coloredbox-green {
  background-color: #80bfa2;
  border-radius: 4px;
  padding: 4px 12px;
}

.coloredbox-red {
  background-color: #ec8a87;
  border-radius: 4px;
  padding: 4px 12px;
}
