@font-face {
  font-family: "Ubuntu";
  font-weight: 500;
  src: local("Ubuntu"), url(./fonts/Ubuntu-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  font-weight: 300;
  src: local("Ubuntu"), url(./fonts/Ubuntu-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  font-weight: 400;
  src: local("Ubuntu"), url(./fonts/Ubuntu-Medium.ttf) format("truetype");
}

#root {
  background-color: white;
}

* {
  font-family: Ubuntu;
  font-weight: 400;
}

.section-title-text {
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: 500;
  color: #333;
}
.info-text-small {
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  color: #888;
}
.info-text-small-white {
  font-family: Ubuntu;
  font-size: 10px;
  font-weight: 400;
  color: white;
}
.big-number-text {
  font-family: Ubuntu;
  font-size: 48px;
  font-weight: 500;
  color: black;
}
.bold-number-text {
  font-family: Ubuntu;
  font-size: 36px;
  font-weight: 500;
  color: black;
}

.app-root-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: white;
  align-items: center;
}

.sidebar-active {
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  width: 20vw;
  margin: 0;
  top: 6vh;
  right: 0;
  transition: 400ms;
  border-radius: 40px 0 0 0;
  overflow: hidden;
  box-shadow: 0px 5px 12px #aaa;
  z-index: 200;
}

.inactive {
  right: -24vw;
  transition: 400ms;
}

.sidebar-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  padding: 12px;
  padding-left: 24px;
  background-color: white;
}

.sidebar-heading-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 20px;
}

.socials-link-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.sizedbox {
  height: 80px;
}
button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.floating-button {
  position: fixed;
  right: 10px;
  bottom: 10px;
  margin: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #f7cf6d;

  display: flex;
  justify-content: center;
  align-items: center;
}

.socials-icon-button {
  cursor: pointer;
  transition: 260ms;
}
.socials-icon-button:hover {
  /* transform: translateY(3px); */
  transform: scale(1.2);
}

.login-page {
  display: flex;
  margin-left: 50px;
  height: 100vh;
}
.login-page-left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 30vw;
}

.login-page-left-header {
  width: 300px;
  height: auto;
  padding: 40px;
}
.login-page-left-footer {
  height: 20%;
}

.login-page-right {
  width: 70vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page-right img {
  width: 70%;
  height: auto;
}

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  --box-shadow: none;
  --amplify-primary-color: #f7cf6d;
  --amplify-primary-contrast: #29282d;
}

amplify-sign-in {
  --footer-color: #29282d;
}
