.navigationdrawer-root {
  width: 100vw;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 100;
}

.navigationdrawer-body {
  display: flex;
  flex-direction: row;
  width: 80vw;
  justify-content: space-around;
  background-color: white;
  height: 80px;
  border-radius: 0px 0px 24px 24px;
  box-shadow: 0px 5px 12px #ccc;
}

.share-navigationdrawer-body {
  display: flex;
  flex-direction: row;
  width: 80vw;
  align-items: center;
  background-color: #f7cf6d;
  opacity: 0.9;
  height: 80px;
  border-radius: 0px 0px 24px 24px;
  box-shadow: 0px 5px 12px #ccc;
  padding: 20px;
}

.share-navigationdrawer-body-heading {
  width: 30%;
  display: flex;
}

.share-navigationdrawer-body-content {
  width: 70%;
  display: flex;
}

.share-navigationdrawer-body-heading .navigationdrawer-item {
  margin-left: 10px;
  margin-right: 10px;
}

.share-floating-button {
  position: fixed;
  left: 20px;
  top: 90px;
  margin: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #f7cf6d;

  display: flex;
  justify-content: center;
  align-items: center;
}

.share-floating-button img {
  transform: rotate(180deg);
}

.navigationdrawer-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigationdrawer-item-text {
  font: Ubuntu san-serif;
  font-weight: bold;
  color: #575757;
  transition: all 400ms;
}

.navigationdrawer-item-text:hover {
  border-bottom: 4px solid #f7cf6d;

  cursor: pointer;
}

.navigationdrawer-item-text-selected {
  font: Ubuntu san-serif;
  font-weight: bold;
  color: #575757;
  transition: all 400ms;
  border-bottom: 4px solid #f7cf6d;

  cursor: pointer;
}

.Link-tag {
  text-decoration: none;
}

.socials-icon-button{
  color: black;
  margin-right: 1rem;
  cursor: pointer;
}