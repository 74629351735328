.view {
  border: solid red 2px;
}

.user-container-root {
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.detail-panel-root {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 48px;
  padding: 16px 24px;
}

.detail-panel-video-card {
  border-radius: 12px;
  box-shadow: 0px 5px 12px #ddd;
  padding: 24px;
}

.user-section-heading-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
}

.filter-button-div {
  border-radius: 4px;
  width: 36px;
  height: 36px;
  border: #eee solid 2px;
  background-color: #f5f6f6;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.filter-button-div-enabled {
  border-radius: 4px;
  width: 36px;
  height: 36px;
  box-shadow: 0px 0px 6px #ccc;
  background-color: white;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
