.upload-root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 80px;
  align-items: center;
  width: 100%;
  height: 72vh;
}
.upload-box-container {
  height: 240px;
  width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: dashed 2px #ccc;
  padding: 16px;
}
.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.upload-options-container {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.file-list-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
}

.file-progress-entry {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
}

.file-name-and-size {
  width: 300px;
}

.upload-pregress-bar {
  width: 200px;
  height: 20px;
}

.topic-name-entry {
}