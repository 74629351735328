.email-settings {
  padding: 40px;
}

.email-settings-heading {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;

  color: #29282d;
}

.email-settings-daily-highlights {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.email-settings-daily-highlights-show {
  display: flex;
  justify-content: space-between;
}

.email-settings-daily-highlights-heading {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  color: #29282d;
}

.email-settings-daily-highlights-sub-heading {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 100;
  font-size: 14px;

  color: #7a7980;
}

.email-settings-daily-highlights-toggle {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 100;
  font-size: 12px;

  color: #7a7980;
}

.email-settings-email {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: "Ubuntu";
  font-style: light;
  font-weight: 300;
  font-size: 16px;

  color: #7a7980;
  width: 100%;
}

.email-settings-email-heading {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  color: #29282d;
}

.email-settings-email-sub-heading {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 100;
  font-size: 14px;

  color: #7a7980;
}

.email-settings-email > div:nth-child(2) {
  width: 50%;
  display: flex;
  align-items: center;
  position: relative;
}

.email-settings-email-input {
  width: 100%;
  height: 50px;
  outline: none;
  border: none;
  border-radius: 20px;
  padding-left: 10px;
}

.email-settings-email-input-enabled {
  width: 100%;
  height: 50px;
  border-radius: 20px;
  padding-left: 10px;
}

.email-settings-email-edit-button {
  cursor: pointer;
  color: #f7cf6d;
}

.email-settings-email-done-buttons {
  display: flex;
  position: absolute;
  right: 10px;
}

.email-settings-email-done-button {
  cursor: pointer;
  color: green;
}

.email-settings-email-cancel-button {
  cursor: pointer;
  color: red;
  margin-right: 10px;
}

.email-settings-loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
