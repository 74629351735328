.notification {
  width: 100%;
  /* display: flex;
  align-items: center; */
  /* border: 1px solid black;
  border-radius: 5px; */
  margin-bottom: 5px;
  box-shadow: 0px 4px 15px rgba(41, 40, 45, 0.12);
  border-radius: 8px;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.notification:hover {
  box-shadow: 0px 4px 15px rgba(41, 40, 45, 0.3);
}

.Notifications-table {
  width: 100%;
}

.icons-wrapper {
  padding: 0.5rem;
  /* margin: 0.5rem 0.5rem 0.5rem 0.5rem; */
  /* margin-bottom: 1rem; */
  margin-right: 0.5rem;
  border-radius: 5px;
}

.textMain {
  margin: 0;
  margin-left: 10px;
}

.text-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.text-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.text-alt {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
}

.icons-wrapper-alt {
  padding: 4px;
  margin-right: 0.5rem;
  /* margin-top: 0 !important; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checked-tab {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.checked-tab-active {
  padding: 0.5rem 1rem;
  background-color: #f7cf6d;
  border-radius: 5px;
  cursor: pointer;
}

.checked-tick:hover {
  opacity: 0.5;
}
