.highlights-root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 540px;
  justify-content: space-between;
}

.highlight-video-card {
  height: 500px;
  width: 240px;
  border-radius: 16px;
  box-shadow: 0px 5px 12px #aaa;
  padding: 24px 12px;
}

.highlight-cards-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
