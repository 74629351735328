.datamanagement-root {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-top: 40px;
  width: 100%;
  align-items: center;
}

.switch-div-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 300px;
  background-color: #ccc;
  border-radius: 100px;
  overflow: hidden;
  font-family: Ubuntu;
  cursor: pointer;
}

.switch-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  color: #333;
  transition: 800ms;
}

.switch-div:hover {
  background-color: #c3c7e5;
}

.switch-div-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  background-color: #5f6ce1;
  color: white;
}
