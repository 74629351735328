.low-score {
	font-family: Roboto Mono;
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	color: #F2726F;
}

.middle-score {
	font-family: Roboto Mono;
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	color: #FFC533;
}

.high-score {
	font-family: Roboto Mono;
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	color: #62B58F;
}

